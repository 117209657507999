import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { BroadcastService, MsalService } from "@azure/msal-angular";
import { MSALError } from "@azure/msal-angular/dist/MSALError";
import { environment, MSAL_CONFIGURATION } from "environments/environment";
import { commonEnv } from "environments/environment.common";
import { Configuration, UserAgentApplication } from "msal";
import { Subscription } from "rxjs";
import { DisplayErrorMessage } from "../app/shared/common/errorDialog";
import { EMsalItemType, EMSALStatus } from "./shared/auth/auth.model";
import { AuthService } from "./shared/auth/auth.service";
import { StateService } from "./shared/services/state.service";
import { AppInsightsService } from "./app-insights.service";

//For Responsiveness testing
import { HostListener } from "@angular/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy {
  loginIsCompleted: boolean;
  title = "iap-web";
  displayedName: string;
  loadingText: string;
  isInvalidLoginAttempt = false;
  loginFailure$: Subscription = null;
  acquireTokenFailure$: Subscription = null;

  private userAgent: UserAgentApplication;

  constructor(
    private router: Router,
    private authService: AuthService,
    private stateService: StateService,
    public msalService: MsalService,
    private broadcastService: BroadcastService,
    public dialog: MatDialog,
    private appInsights: AppInsightsService
  ) {
    //For testing Responsiveness
    this.getScreenSize();

    this.loginIsCompleted = false;

    this.loginFailure$ = this.broadcastService.subscribe(
      EMsalItemType.LOGIN_FAILURE,
      (payload: MSALError) => {
        this.isInvalidLoginAttempt = true;
        this.handleLoginFailure(payload);
      }
    );

    this.acquireTokenFailure$ = this.broadcastService.subscribe(
      EMsalItemType.ACQUIRE_TOKEN_FAILURE,
      (payload: MSALError) => {
        this.isInvalidLoginAttempt = true;
        this.handleAcquiredTokenFailure(payload);
      }
    );

    if (environment.ssoEnabled) {
      this.userAgent = new UserAgentApplication(MSAL_CONFIGURATION);
      this.userAgent.handleRedirectCallback(this.handleRedirectCallback);
    }
  }

  handleRedirectCallback(error, response) {
    console.log("Inside handleRedirectCallback");
  }

  isLoginCompleted() {
    return this.loginIsCompleted;
  }

  width: number;

  //For testing Responsiveness
  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.width = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    );
    //console.log(`Screen Width:${this.width}`);
  }

  ngOnInit(): void {
    this.loadingText = "Loading Application Information... Please Wait";

    if (environment.ssoEnabled) {
      if (!this.userAgent.getLoginInProgress()) {
        const userInfo = this.authService.getUserInfo();
        if (userInfo) {
          this.displayedName = userInfo.name;

          if (
            userInfo.acr.toLocaleLowerCase() ===
            environment.agentSignInPolicy.toLocaleLowerCase()
          ) {
            if (
              this.authService.isAgent() ||
              this.authService.isAgencyAdmin() ||
              this.authService.isCommissionsUser()
            ) {
              this.stateService.agentId = "SIDARTH.GUPTA";
              this.loginIsCompleted = true;
            } else {
              DisplayErrorMessage(
                this.dialog,
                "",
                "Sorry, you do not have access to this application.",
                "logout",
                "Close"
              );
            }
          }
        } else {
          this.authService.login();
        }
      }
    } else {
      if (!this.msalService.loginInProgress()) {
        this.loginIsCompleted = true;
        const userInfo = this.authService.getUserInfo();

        if (userInfo) {
          this.displayedName = userInfo.name;

          if (userInfo.tfp === environment.agentSignInPolicy) {
            this.authService.verifyAgent().subscribe(
              verifyResult => {
                this.stateService.agentId = verifyResult.agentId;
              },
              (err: any) => {
                console.log("error verifyAgent is:" + err.message);
                DisplayErrorMessage(
                  this.dialog,
                  "",
                  "Sorry, you do not have access to this application.",
                  "logout",
                  "Close"
                );
              }
            );
          }
        }
      }
    }
  }

  handleLoginFailure = (payload: MSALError) => {
    const { errorDesc } = payload;

    if (environment.ssoEnabled) {
      if (errorDesc && errorDesc.indexOf(EMSALStatus.FORGOT_PASSWORD) !== -1) {
        this.msalService.authority = `${environment.azureInstance}/${environment.tenant}/${environment.resetPasswordPolicy}`;
        this.msalService.loginRedirect();
      } else if (
        errorDesc &&
        errorDesc.indexOf(EMSALStatus.CANCEL_RESET_PASSWORD) !== -1
      ) {
        this.msalService.authority = `${environment.azureInstance}/${environment.tenant}/${environment.agentSignInPolicy}`;
        this.authService.login();
      } else if (
        errorDesc &&
        errorDesc.indexOf(EMSALStatus.MULTIPLE_ACCOUNTS) !== -1
      ) {
        this.msalService.authority = `${environment.azureInstance}/${environment.tenant}/${environment.agentSignInPolicy}`;
        this.authService.login();
      } else {
        // display error: errorDesc and do logout
        this.authService.logout();
      }
    } else {
      if (errorDesc && errorDesc.indexOf(EMSALStatus.FORGOT_PASSWORD) !== -1) {
        this.msalService.authority = `${environment.azureInstance}/${environment.tenant}/${environment.resetPasswordPolicy}`;
        this.msalService.loginRedirect();
      } else if (
        errorDesc &&
        errorDesc.indexOf(EMSALStatus.CANCEL_RESET_PASSWORD) !== -1
      ) {
        this.msalService.authority = `${environment.azureInstance}/${environment.tenant}/${environment.applicantSignInPolicy}`;
        this.authService.login();
      } else if (
        errorDesc &&
        errorDesc.indexOf(EMSALStatus.MULTIPLE_ACCOUNTS) !== -1
      ) {
        this.msalService.authority = `${environment.azureInstance}/${environment.tenant}/${environment.applicantSignInPolicy}`;
        this.authService.login();
      } else {
        // display error: errorDesc and do logout
        this.authService.logout();
      }
    }
  };

  // TODO: handle specific error with a friendly error message
  handleAcquiredTokenFailure = (payload: MSALError) => {
    const { error } = payload;

    console.log(error);

    if (error && error.indexOf(EMSALStatus.SESSION_TIME_OUT) !== -1) {
      // display error and do logout
      //'Session time out, please login again..',
      this.authService.logout();
    } else if (error && error.indexOf(EMSALStatus.MULTIPLE_ACCOUNTS) !== -1) {
      // display error and do logout
      //'Multiple Microsoft accounts detected, make sure you logged out other accounts before logging in',
      this.authService.logout();
    } else {
      // display error: errorDesc and do logout
      this.authService.logout();
    }
  };

  ngOnDestroy(): void {}
}
