import { Component, OnInit, ViewChild } from "@angular/core";
import { UserService } from "../../services/user.service";
import { DialogService } from "app/iap/services/dialog.service";
import {
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {
  MatPaginator,
  MatSort,
  MatTableDataSource,
  Sort
} from "@angular/material";
import { AuthService } from "app/shared/auth/auth.service";
import { IAgent } from "app/iap/models/user.model";
import {
  FormValidationService,
  emailValidator,
  lowercaseValidator,
  uppercaseValidator,
  digitValidator,
  symbolValidator,
  passwordMatchValidator
} from "app/shared/services/form-validation.service";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"]
})
export class UsersComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  currentUser: any;
  userTableError: any;
  userForm: FormGroup;
  dataSource: MatTableDataSource<any>;
  ELEMENT_DATA: IAgent[] = [];
  displayedColumns: string[] = [
    "LastName",
    "FirstName",
    "EmailAddress",
    "Role"
    // "deleteButton"
  ];

  constructor(
    private userService: UserService,
    private authService: AuthService,
    public formValidationService: FormValidationService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.initializeUserForm();
    this.refreshData();
    this.currentUser = this.authService.getUserInfo();
    console.log(this.currentUser.email);
  }

  private initializeUserForm() {
    this.userForm = new FormGroup(
      {
        displayName: new FormControl("", [Validators.required]),
        firstName: new FormControl("", [Validators.required]),
        lastName: new FormControl("", [Validators.required]),
        email: new FormControl("", [Validators.required, emailValidator]),
        password: new FormControl("", [
          Validators.required,
          lowercaseValidator,
          uppercaseValidator,
          digitValidator,
          symbolValidator,
          Validators.minLength(8),
          Validators.maxLength(16)
        ]),
        passwordconfirm: new FormControl("", [Validators.required])
      },
      passwordMatchValidator
    );
  }

  createUser() {
    if (this.userForm.valid) {
      var body = {
        displayName: this.userForm.value.displayName,
        firstName: this.userForm.value.firstName,
        lastName: this.userForm.value.lastName,
        email: this.userForm.value.email,
        password: this.userForm.value.password
      };

      const confResult = this.dialogService.openDialog(
        "confirmChanges",
        "Confirm Action",
        "Are you sure you want to submit?",
        "Cancel",
        "Submit"
      );

      confResult.afterClosed().subscribe(dialogRes => {
        if (dialogRes) {
          this.userService.createAgentUser(body).subscribe(
            result => {
              this.clearForm();
              this.dialogService.MessageBox(
                "Agent Created",
                "X",
                5000,
                "SuccessMessage"
              );
            },
            err => {
              this.dialogService.MessageBox(
                "Agent Creation Failed",
                "Informational",
                5000,
                "ErrorMessage"
              );
            }
          );
        }
      });
    }
  }

  clearForm() {
    this.userForm.value.displayName = "";
    this.userForm.value.firstName = "";
    this.userForm.value.lastName = "";
    this.userForm.value.email = "";
    this.userForm.value.password = "";
    // location.reload();
    this.refreshData();
  }

  refreshData(): void {
    this.userService.getAgents(this.authService.getUserInfo().oid).subscribe(
      result => {
        console.log("user list", result);
        // Implement code to remove duplicate agents using hashmaps.

        const uniqueAgents = new Map<string, IAgent>();
        result.forEach(element => {
          if (!uniqueAgents.has(element.ID)) {
            uniqueAgents.set(element.ID, element);
          } else {
            uniqueAgents.get(element.ID)["RoleName"] += ", " + element.RoleName;
          }
        });
        this.ELEMENT_DATA = Array.from(uniqueAgents.values());
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      },
      err => {
        console.error(err);
        console.log(err.status);
        this.userTableError = err;
      }
    );
  }

  deleteUser(userObject: IAgent): void {
    console.log(userObject);
    if (userObject.EmailAddress === this.currentUser.email) {
      this.dialogService.ErrorDialog(
        "Cannot delete own user",
        "Cannot delete own user",
        null,
        "Ok"
      );
    } else {
      const confResult = this.dialogService.openDialog(
        "confirmChanges",
        "Confirm Action",
        "Are you sure you want to delete user " +
          userObject.FirstName +
          " " +
          userObject.LastName +
          "?",
        "Cancel",
        "Delete"
      );

      confResult.afterClosed().subscribe(dialogRes => {
        if (dialogRes) {
          this.userService.deleteAgent(userObject.ID).subscribe(result => {
            this.refreshData();
          });
        }
      });
    }
  }

  /* Called on each input in either password field */
  onPasswordInput() {
    if (this.userForm.hasError("passwordMismatch")) {
      this.userForm.controls.passwordconfirm.setErrors([
        { passwordMismatch: true }
      ]);
    } else {
      this.userForm.controls.passwordconfirm.setErrors(null);
    }
  }
}
