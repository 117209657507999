import { animate, style, transition, trigger } from "@angular/animations";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatSidenav } from "@angular/material";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { NavigationStart, Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

import { NavigationHelper } from "../../common/navigation";
import { AuthService } from "../../auth/auth.service";

@Component({
  selector: "app-main-nav",
  templateUrl: "./main-nav.component.html",
  styleUrls: ["./main-nav.component.css"],
  animations: [
    trigger("footerEaseOut", [
      transition(":leave", [
        style({ transform: "translateX(0)", opacity: 1 }),
        animate("300ms", style({ transform: "translateX(100%)", opacity: 0 }))
      ])
    ])
  ]
})
export class MainNavComponent implements OnInit, OnDestroy {
  isHandset$: Observable<boolean>;
  destroyed$: any;
  navLinks: any[];
  activeLinkIndex: number;
  isExpanded: boolean;
  isOpened: boolean;
  showFooter: boolean;

  @ViewChild("drawer") drawer: MatSidenav;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private authService: AuthService
  ) {
    this.showFooter = true;
    this.isExpanded = true;
    this.isOpened = true;
    this.activeLinkIndex = -1;

    this.destroyed$ = new Subject();

    this.loadFooterRightIcons();

    this.isHandset$ = this.breakpointObserver
      .observe([Breakpoints.Handset, Breakpoints.Tablet])
      .pipe(
        map(result => {
          // console.log(result.breakpoints);

          if (result.matches && window.innerWidth < 1280) {
            // console.log("screen width is:" + window.innerWidth);
            // console.log("handheld or tablet detected");
            return true;
          }

          // console.log("desktop detected");
          return false;
        }),
        takeUntil(this.destroyed$)
      );
  }

  public ngOnInit() {
    this.router.events.subscribe(res => {
      if (res) {
        const moduleURL = (res as NavigationStart).url;
        // get the links for a module only if the url is the root
        if (
          moduleURL != undefined &&
          (moduleURL.lastIndexOf("/") === 0 ||
            moduleURL.lastIndexOf("/iap") === 0)
        ) {
          this.navLinks = NavigationHelper.GetNavLinks(moduleURL);
          // check for roles and remove the Manage users link
          // console.log("Nav Page");
          // console.log(this.navLinks);
          // console.log(this.authService.isAgencyAdmin());
          // console.log(this.authService.isCommissionsUser());

          if (!this.authService.isAgencyAdmin()) {
            // console.log("User is not Agency Admin");
            // remove the Manage Users Link
            this.navLinks.forEach((element, index) => {
              if (element.label === "Manage Agents") {
                this.navLinks.splice(index, 1);
              }
            });
          }

          if (!this.authService.isCommissionsUser()) {
            // console.log("User is not Commission User");
            this.navLinks.forEach((element, index) => {
              if (element.label === "Commissions") {
                this.navLinks.splice(index, 1);
              }
            });
          }

          if (!this.authService.isOnboardingUser()) {
            // Remove links to onboarding and app updates if user does not onboardingUser role.
            // console.log("User is not Onboarding User");
            this.navLinks.forEach((element, index) => {
              if (element.label === "Onboarding") {
                this.navLinks.splice(index, 1);
              }
            });
          }

          console.log(this.navLinks);
        }

        if (this.navLinks) {
          //console.log("this.router.url is:" +  this.router.url);

          let tab = this.navLinks.find(tab => {
            //console.log("tab.link is:" + tab.link);

            if (tab.link === "./iap/") {
              return tab.link === "." + this.router.url + "/";
            }

            return tab.link === "." + this.router.url;
          });

          if (tab) {
            this.activeLinkIndex = tab.index;

            //console.log("this.activeLinkIndex is:" + this.activeLinkIndex);
          }
        }
      }
    });
    this.router.events.subscribe(res => {});
  }

  public isLinkActive(index: any) {
    let retVal = "";

    retVal = this.activeLinkIndex === index ? "active-link" : "";

    return retVal;
  }

  public ToogleHeader() {
    if (this.drawer.mode === "over") {
      this.drawer.opened = !this.drawer.opened;
    }
  }

  public ToggleSideNav() {
    if (this.drawer.mode !== "over") {
      this.isExpanded = !this.isExpanded;
    }
  }

  public onHomeClick = () => {
    this.router.navigateByUrl("/");
  };

  public openLink(url: string) {
    window.open(url, "_blank");
  }

  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }

  private showPanelItem(link: any, isExpanded: boolean) {
    const retValue = link.isPanelParent === true && isExpanded === true;

    return retValue;
  }

  private loadFooterRightIcons() {
    this.matIconRegistry.addSvgIcon(
      "facebook",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/facebook-f-brands.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "instagram",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/instagram-brands.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "linkedin",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/linkedin-brands.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "twitter",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/twitter-brands.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "youtube",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/youtube-brands.svg"
      )
    );
  }
}
