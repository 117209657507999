import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { LookupsService } from "app/iap/services/lookups.service";
import { MyProfileService } from "app/iap/services/my-profile.service";
import { IUser } from "app/shared/auth/auth.model";
import { ContactInformationService } from "app/iap/services/contact-information.service";
import { AuthService } from "app/shared/auth/auth.service";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";

// Create the agent data
export interface AgentData {
  agent: string;
  data: string;
  bold?: boolean;
}

// Populate table with some default data
const ELEMENT_DATA: AgentData[] = [
  { agent: "Company Name", data: "" },
  { agent: "Status", data: "", bold: true },
  { agent: "Certification Expiration Date", data: "" },
  { agent: "Authorized Countries of Recruitment", data: "" },
  { agent: "Director Region", data: "" },
  { agent: "Agency Since", data: "" }
];

@Component({
  selector: "app-agent-onboarding",
  templateUrl: "./agent-onboarding.component.html",
  styleUrls: ["./agent-onboarding.component.scss"]
})
export class AgentOnboardingComponent implements OnInit {
  user: IUser;
  hasAccess: boolean = false;
  isAdmin: boolean = false; // We can data bind to this to pass it to child components
  displayedColumns: string[] = ["agent", "data"];
  dataSource = ELEMENT_DATA;
  agencySub: Subscription;
  countries: any = [];
  agentId: number;
  oldAgencyId: string;
  agencyId: string;
  authCountryArr = [];
  isLoading: boolean = true;
  contactInfoStatus: string;
  mandatoryDocsStatus: string;
  businessInfoStatus: string;
  ContactInfo: any;
  CompanyInfo: any;

  constructor(
    private authService: AuthService,
    private myProfileService: MyProfileService,
    private lookupService: LookupsService,
    private contactInfoService: ContactInformationService
  ) {}

  async ngOnInit() {
    this.user = this.authService.getUserInfo();

    this.loadCountries();

    // Checking for roles
    if (
      this.authService.hasRole("Agent") ||
      this.authService.hasRole("Agency_Admin")
    ) {
      this.hasAccess = true;
      if (this.authService.hasRole("Agency_Admin")) {
        this.isAdmin = true;
      }
    } else {
      this.hasAccess = false;
    }

    await this.loadAgencyInfo();
    // this.getOnboardingStatus();
    // this.loadTable();
  }

  loadCountries() {
    // Use the lookup service to get Countries and Codes
    this.lookupService
      .getLookupByTableAlias("country")
      .pipe(take(1))
      .subscribe(country => {
        this.countries = country;
      });
  }

  loadTable() {
    // Load the Agency table subscribing the getAgency Service
    this.agencySub = this.myProfileService
      .getAgencyInformation(this.user.oid)
      .subscribe(
        agencyInfo => {
          if (
            !agencyInfo.authCountries ||
            agencyInfo.authCountries.length === 0
          ) {
            this.authCountryArr = ["NA"];
          } else {
            this.authCountryArr = agencyInfo.authCountries.split(",");
          }
          let authCountries = [];
          this.authCountryArr.map(countryCode => {
            if (countryCode === "NA") {
              authCountries.push("No Authorized Countries");
            }

            for (let i = 0; i < this.countries.length; ++i) {
              if (this.countries[i].Code === countryCode) {
                authCountries.push(this.countries[i].Name);
              }
            }
          });

          // Populate the table with data
          this.agencyId = agencyInfo.agencyId;
          this.oldAgencyId = agencyInfo.oldAgencyID;
          this.dataSource[0].data = agencyInfo.companyName;
          if (agencyInfo.status === "A") {
            this.dataSource[1].data = "Complete";
          } else if (agencyInfo.status === "B") {
            this.dataSource[1].data = "Probationary";
          } else {
            this.dataSource[1].data = "Undefined";
          }

          let expDate = new Date(Date.parse(agencyInfo.certExpiryDate));
          expDate.setDate(expDate.getDate() + 1);
          this.dataSource[2].data = expDate.toDateString();
          this.dataSource[3].data = authCountries.join(", ");
          this.dataSource[4].data = agencyInfo.directorRegionName;
          let sinceDate = new Date(Date.parse(agencyInfo.agencySince));
          sinceDate.setDate(sinceDate.getDate() + 1);
          this.dataSource[5].data = sinceDate.toDateString();
          this.isLoading = false;
        },
        error => {
          console.log(error.message);
        }
      );
  }

  // getOnboardingStatus(){
  //   this.contactInfoService.getAgencyOnboardingStatus(this.agencyId)
  //     .subscribe((status: any) => {
  //       console.log("Agency Onboarding Status: ", status);
  //       this.contactInfoStatus = status.AgentContact;
  //       this.mandatoryDocsStatus = status.CompanyDocuments;
  //       this.businessInfoStatus = status.CompanyInfo;
  //     });
  // }

  // loadAgencyInfo() {
  //   console.log("User OID in the agent onboarding: ", this.user.oid);
  //   this.contactInfoService.getContactInfoById(this.user.oid)
  //     .subscribe((agencyInfo: any) => {
  //       console.log("Agency Info: ", agencyInfo);
  //       this.agentId = agencyInfo.Agent_ID.toString();
  //       this.agencyId = agencyInfo.Agency_ID.toString();
  //     });
  // }

  async loadAgencyInfo() {
    console.log("User OID in the agent onboarding: ", this.user.oid);
    return new Promise<void>((resolve, reject) => {
      this.contactInfoService.getContactInfoById(this.user.oid).subscribe(
        (agencyInfo: any) => {
          console.log("Agency Info: ", agencyInfo);
          this.agentId = agencyInfo.Agent_ID.toString();
          this.agencyId = agencyInfo.Agency_ID.toString();
          resolve();
        },
        error => {
          reject(error);
        }
      );
    });
  }

  saveContactInfo($event: any) {
    console.log("Data in the parent component: ", $event.contactFormData);
    this.ContactInfo = $event;
  }

  saveCompanyInfo($event: any) {
    console.log("Data in the parent component: ", $event);
    this.CompanyInfo = $event;
  }

  ngOnDestroy() {
    this.agencySub.unsubscribe();
  }
}
