import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./components/home/home.component";
import { AgentOnboardingComponent } from "./components/agent-onboarding/agent-onboarding.component";
import { MyProfileComponent } from "./components/my-profile/my-profile.component";
import { ApplicationsComponent } from "./components/applications/applications.component";
import { ApplicationUpdatesComponent } from "./components/application-updates/application-updates.component";
import { CommissionsComponent } from "./components/commissions/commissions.component";
import { ContactsComponent } from "./components/contacts/contacts.component";
import { NewsfeedComponent } from "./components/newsfeed/newsfeed.component";
import { ResourcesForAgenciesComponent } from "./components/resources/resources-for-agencies/resources-for-agencies.component";
import { LogoutComponent } from "./components/logout/logout.component";
import { RequestForMaterialComponent } from "./components/resources/request-for-material/request-for-material.component";
import { ThankYouComponent } from "./components/resources/thank-you/thank-you.component";
import { NewsfeedformComponent } from "./components/newsfeed/newsfeedform/newsfeedform.component";
import { NewsfeedlistComponent } from "./components/newsfeedlist/newsfeedlist.component";
import { NewsfeedviewComponent } from "./components/newsfeedview/newsfeedview.component";
import { AnnouncementComponent } from "./components/announcement/announcement.component";
import { AuthGuard } from "app/shared/auth/auth.guard";
import { LoginComponent } from "./components/login/login.component";
import { UsersComponent } from "./components/users/users.component";
import { UsersGuard } from "./components/users/users.guard";
import { EditContactInformationComponent } from "./components/my-profile/profile-panels/my-profile-contact-information/edit-contact-information/edit-contact-information.component";
import { LookUpResolver } from "app/shared/resolvers/lookup.resolver";
import { SenecaContactsComponent } from "./components/seneca-contacts/seneca-contacts.component";
import { ViewSubmittedOriginalComponent } from "./components/commissions/submitted-requests-original/view-submitted/view-submitted.component";
import { DisputedRequestComponent } from "./components/commissions/new-requests/disputed-requests/disputed-request.component";
import { DisputedRequestAllComponent } from "./components/commissions/new-requests/disputed-requests-all/disputed-request-all.component";
import { DisputedRequestSubmittedComponent } from "./components/commissions/submitted-requests/disputed-requests-submitted/disputed-request-submitted.component";
import { ViewSubmittedComponent } from "./components/commissions/submitted-requests/view-submitted/view-submitted.component";
import { ApplicationDetailsComponent } from "./components/application-updates/application-details/application-details.component";

const routes: Routes = [
  /*
  {
    path: "",
    redirectTo: "login"
  },
  {
    path: "login",
    component: LoginComponent
  },
  */
  {
    path: "",
    component: HomeComponent
  },
  {
    path: "agent-onboarding",
    // children: [{ path: "", component: AgentOnboardingComponent }]
    component: AgentOnboardingComponent
  },
  {
    path: "my-profile",
    children: [{ path: "", component: MyProfileComponent }]
  },
  {
    path: "application-updates",
    component: ApplicationUpdatesComponent
  },
  {
    path: "application-updates/application-details/:id",
    component: ApplicationDetailsComponent
  },
  {
    path: "applications",
    component: ApplicationsComponent
  },
  {
    path: "commissions",
    component: CommissionsComponent
  },
  {
    path: "commissions/view-requests/:requestTypeID/:id/:term/:term0/:agencyID",
    component: ViewSubmittedComponent
  },
  {
    path: "commissions/view-requests/:id",
    component: ViewSubmittedOriginalComponent
  },
  {
    path: "announcement",
    component: AnnouncementComponent
  },
  {
    path: "resources",
    component: ResourcesForAgenciesComponent
  },
  {
    path: "resources/requestformaterial",
    component: RequestForMaterialComponent
  },
  {
    path: "commissions/disputed-request/:id/:term/:term1/:agencyID",
    component: DisputedRequestComponent
  },
  {
    path: "commissions/disputed-request-all/:agencyID",
    component: DisputedRequestAllComponent
  },
  {
    path:
      "commissions/disputed-request-submitted/:requestTypeID/:id/:term/:term0/:agencyID",
    component: DisputedRequestSubmittedComponent
  },
  {
    path: "resources/thankyou",
    component: ThankYouComponent
  },
  {
    path: "newsfeed",
    component: NewsfeedComponent
  },
  {
    path: "newsfeed/addnews",
    component: NewsfeedformComponent
  },
  {
    path: "newsfeed/addnews/:newsfeedid",
    component: NewsfeedformComponent
  },
  {
    path: "listnewsfeeds",
    component: NewsfeedlistComponent
  },
  {
    path: "listnewsfeeds/viewnewsfeed/:newsfeedid",
    component: NewsfeedviewComponent
  },
  {
    path: "contacts",
    component: ContactsComponent
  },
  {
    path: "seneca-contacts",
    component: SenecaContactsComponent
  },
  {
    path: "logout",
    component: LogoutComponent
  },
  // commenting out to disable route
  {
    path: "users",
    component: UsersComponent,
    canActivate: [UsersGuard],
    canLoad: [UsersGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [LookUpResolver]
})
export class IAPRoutingModule {
  public static GetNavLinks(): any[] {
    return [
      /*
      {
        label: "Login",
        link: "./iap/login",
        iconName: "input",
        index: 0
      },
      */
      {
        label: "Home",
        link: "./iap/",
        iconName: "home",
        index: 0
      },
      {
        label: "Onboarding",
        link: "./iap/agent-onboarding",
        iconName: "person",
        index: 1
      },
      {
        label: "My Profile",
        link: "./iap/my-profile",
        iconName: "person",
        index: 2
      },
      {
        label: "Application Updates",
        link: "./iap/application-updates",
        iconName: "updates",
        index: 3
      },
      // {
      //   label: "Applications",
      //   link: "./iap/applications",
      //   iconName: "folder_shared",
      //   index: 2
      // },
      {
        label: "Commissions",
        link: "./iap/commissions",
        iconName: "monetization_on",
        index: 4
      },
      // {
      //   label: "Announcement",
      //   link: "./iap/announcement",
      //   iconName: "rss_feed",
      //   index: 4
      // },
      {
        label: "Resources",
        link: "./iap/resources",
        iconName: "local_library",
        index: 5
      },
      // {
      //   label: "Newsfeed",
      //   link: "./iap/newsfeed",
      //   iconName: "pan_tool",
      //   index: 5
      // },
      {
        label: "Newsfeed",
        link: "./iap/listnewsfeeds",
        iconName: "rss_feed",
        index: 6
      },
      // commented out to hide link
      {
        label: "Manage Agents",
        link: "./iap/users",
        iconName: "groups",
        index: 7
      },
      {
        label: "Seneca Contacts",
        link: "./iap/seneca-contacts",
        iconName: "help",
        index: 8
      },
      {
        label: "Logout",
        link: "./iap/logout",
        iconName: "exit_to_app",
        index: 9
      }
    ];
  }
}
