import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { LookupsService } from "app/iap/services/lookups.service";
import { MyProfileService } from "app/iap/services/my-profile.service";
import { ContactInformationService } from "app/iap/services/contact-information.service";
import { AuthService } from "app/shared/auth/auth.service";
import { take } from "rxjs/operators";
// Populate table with some default data
var ELEMENT_DATA = [
    { agent: "Company Name", data: "" },
    { agent: "Status", data: "", bold: true },
    { agent: "Certification Expiration Date", data: "" },
    { agent: "Authorized Countries of Recruitment", data: "" },
    { agent: "Director Region", data: "" },
    { agent: "Agency Since", data: "" }
];
var AgentOnboardingComponent = /** @class */ (function () {
    function AgentOnboardingComponent(authService, myProfileService, lookupService, contactInfoService) {
        this.authService = authService;
        this.myProfileService = myProfileService;
        this.lookupService = lookupService;
        this.contactInfoService = contactInfoService;
        this.hasAccess = false;
        this.isAdmin = false; // We can data bind to this to pass it to child components
        this.displayedColumns = ["agent", "data"];
        this.dataSource = ELEMENT_DATA;
        this.countries = [];
        this.authCountryArr = [];
        this.isLoading = true;
    }
    AgentOnboardingComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.user = this.authService.getUserInfo();
                        this.loadCountries();
                        // Checking for roles
                        if (this.authService.hasRole("Agent") ||
                            this.authService.hasRole("Agency_Admin")) {
                            this.hasAccess = true;
                            if (this.authService.hasRole("Agency_Admin")) {
                                this.isAdmin = true;
                            }
                        }
                        else {
                            this.hasAccess = false;
                        }
                        return [4 /*yield*/, this.loadAgencyInfo()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AgentOnboardingComponent.prototype.loadCountries = function () {
        var _this = this;
        // Use the lookup service to get Countries and Codes
        this.lookupService
            .getLookupByTableAlias("country")
            .pipe(take(1))
            .subscribe(function (country) {
            _this.countries = country;
        });
    };
    AgentOnboardingComponent.prototype.loadTable = function () {
        var _this = this;
        // Load the Agency table subscribing the getAgency Service
        this.agencySub = this.myProfileService
            .getAgencyInformation(this.user.oid)
            .subscribe(function (agencyInfo) {
            if (!agencyInfo.authCountries ||
                agencyInfo.authCountries.length === 0) {
                _this.authCountryArr = ["NA"];
            }
            else {
                _this.authCountryArr = agencyInfo.authCountries.split(",");
            }
            var authCountries = [];
            _this.authCountryArr.map(function (countryCode) {
                if (countryCode === "NA") {
                    authCountries.push("No Authorized Countries");
                }
                for (var i = 0; i < _this.countries.length; ++i) {
                    if (_this.countries[i].Code === countryCode) {
                        authCountries.push(_this.countries[i].Name);
                    }
                }
            });
            // Populate the table with data
            _this.agencyId = agencyInfo.agencyId;
            _this.oldAgencyId = agencyInfo.oldAgencyID;
            _this.dataSource[0].data = agencyInfo.companyName;
            if (agencyInfo.status === "A") {
                _this.dataSource[1].data = "Complete";
            }
            else if (agencyInfo.status === "B") {
                _this.dataSource[1].data = "Probationary";
            }
            else {
                _this.dataSource[1].data = "Undefined";
            }
            var expDate = new Date(Date.parse(agencyInfo.certExpiryDate));
            expDate.setDate(expDate.getDate() + 1);
            _this.dataSource[2].data = expDate.toDateString();
            _this.dataSource[3].data = authCountries.join(", ");
            _this.dataSource[4].data = agencyInfo.directorRegionName;
            var sinceDate = new Date(Date.parse(agencyInfo.agencySince));
            sinceDate.setDate(sinceDate.getDate() + 1);
            _this.dataSource[5].data = sinceDate.toDateString();
            _this.isLoading = false;
        }, function (error) {
            console.log(error.message);
        });
    };
    // getOnboardingStatus(){
    //   this.contactInfoService.getAgencyOnboardingStatus(this.agencyId)
    //     .subscribe((status: any) => {
    //       console.log("Agency Onboarding Status: ", status);
    //       this.contactInfoStatus = status.AgentContact;
    //       this.mandatoryDocsStatus = status.CompanyDocuments;
    //       this.businessInfoStatus = status.CompanyInfo;
    //     });
    // }
    // loadAgencyInfo() {
    //   console.log("User OID in the agent onboarding: ", this.user.oid);
    //   this.contactInfoService.getContactInfoById(this.user.oid)
    //     .subscribe((agencyInfo: any) => {
    //       console.log("Agency Info: ", agencyInfo);
    //       this.agentId = agencyInfo.Agent_ID.toString();
    //       this.agencyId = agencyInfo.Agency_ID.toString();
    //     });
    // }
    AgentOnboardingComponent.prototype.loadAgencyInfo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                console.log("User OID in the agent onboarding: ", this.user.oid);
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.contactInfoService.getContactInfoById(_this.user.oid).subscribe(function (agencyInfo) {
                            console.log("Agency Info: ", agencyInfo);
                            _this.agentId = agencyInfo.Agent_ID.toString();
                            _this.agencyId = agencyInfo.Agency_ID.toString();
                            resolve();
                        }, function (error) {
                            reject(error);
                        });
                    })];
            });
        });
    };
    AgentOnboardingComponent.prototype.saveContactInfo = function ($event) {
        console.log("Data in the parent component: ", $event.contactFormData);
        this.ContactInfo = $event;
    };
    AgentOnboardingComponent.prototype.saveCompanyInfo = function ($event) {
        console.log("Data in the parent component: ", $event);
        this.CompanyInfo = $event;
    };
    AgentOnboardingComponent.prototype.ngOnDestroy = function () {
        this.agencySub.unsubscribe();
    };
    return AgentOnboardingComponent;
}());
export { AgentOnboardingComponent };
