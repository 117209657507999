import * as tslib_1 from "tslib";
import { HttpClient } from "@angular/common/http";
import { MsalService } from "@azure/msal-angular";
import { environment, MSAL_CONFIGURATION } from "environments/environment";
import { UserAgentApplication } from "msal";
import * as i0 from "@angular/core";
import * as i1 from "@azure/msal-angular/dist/msal.service";
import * as i2 from "@angular/common/http";
var AuthService = /** @class */ (function () {
    function AuthService(msalService, http //,private appInsights: AppInsightsService
    ) {
        var _this = this;
        this.msalService = msalService;
        this.http = http;
        this.acquireToken = function () {
            if (environment.ssoEnabled) {
                var newRequest = {
                    scopes: [environment.clientId]
                };
                return _this.userAgent.acquireTokenSilent(newRequest);
            }
            else {
                return _this.msalService.acquireTokenSilent(environment.scopes);
            }
        };
        this.isAuthenticated = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var userInfo;
            return tslib_1.__generator(this, function (_a) {
                if (environment.ssoEnabled) {
                    userInfo = this.getUserInfo();
                    /*
                    if (userInfo && userInfo.rolesOnly) {
                      this.appInsights.instance.setAuthenticatedUserContext(
                        userInfo.sub,
                        userInfo.rolesOnly[0]
                      );
                    } else {
                      this.appInsights.instance.clearAuthenticatedUserContext();
                    }
                    */
                }
                return [2 /*return*/, !!this.getUserInfo()];
            });
        }); };
        this.login = function () {
            if (environment.ssoEnabled) {
                var newRequest = {
                    scopes: [environment.clientId]
                };
                _this.msalService.loginRedirect([environment.clientId]);
            }
            else {
                return _this.msalService.loginRedirect(environment.scopes);
            }
        };
        this.logout = function () {
            if (environment.ssoEnabled) {
                return _this.userAgent.logout();
            }
            else {
                return _this.msalService.logout();
            }
        };
        this.getUserInfo = function () {
            if (environment.ssoEnabled) {
                var userData = _this.userAgent.getAccount();
                if (userData) {
                    var user_1 = userData.idToken;
                    if (environment.test_roles) {
                        // test roles test cases overriding roles on the client
                        user_1.roles = _this.getRolesTestCase(2);
                    }
                    var rolesOnly_1 = [];
                    var permissionsOnly_1 = [];
                    if (user_1.roles.length > 0) {
                        user_1.roles.split(", ").forEach(function (role) {
                            var roleSplitArray = role.split("-");
                            if (roleSplitArray[0]) {
                                var roleOnly = roleSplitArray[0].toString();
                                if (!rolesOnly_1.includes(roleOnly)) {
                                    rolesOnly_1.push(roleOnly);
                                }
                                user_1.rolesOnly = rolesOnly_1;
                            }
                            if (roleSplitArray[1]) {
                                var permissionOnly = roleSplitArray[1].toString();
                                if (!permissionsOnly_1.includes(permissionOnly)) {
                                    permissionsOnly_1.push(permissionOnly);
                                }
                            }
                            user_1.permissionsOnly = permissionsOnly_1;
                        });
                    }
                    //log user's roles
                    // console.log("user roles are:" + user.rolesOnly);
                    //log user's permission
                    // console.log("user permission are:" + user.permissionsOnly);
                    return user_1;
                }
            }
            else {
                var userData = _this.msalService.getUser();
                return userData ? userData.idToken : null;
            }
            return null;
        };
        this.getCacheAccessToken = function () {
            if (environment.ssoEnabled) {
                var scopes = environment.scopes;
                // console.log(
                //   "this.msalService.getUser().userIdentifier is: " +
                //     this.msalService.getUser().userIdentifier
                // );
                var key = {
                    authority: _this.msalService.authority,
                    clientId: environment.clientId,
                    scopes: scopes[0],
                    userIdentifier: _this.msalService.getUser().userIdentifier
                };
                var storageValue = localStorage.getItem(JSON.stringify(key));
                return storageValue ? JSON.parse(storageValue) : null;
            }
            else {
                var scopes = [environment.clientId];
                var key = {
                    authority: _this.msalService.authority,
                    clientId: environment.clientId,
                    scopes: scopes[0],
                    userIdentifier: _this.msalService.getUser().userIdentifier
                };
                var storageValue = sessionStorage.getItem(JSON.stringify(key));
                return storageValue ? JSON.parse(storageValue) : null;
            }
        };
        if (environment.ssoEnabled) {
            this.userAgent = new UserAgentApplication(MSAL_CONFIGURATION);
        }
    }
    AuthService.prototype.hasRole = function (name) {
        var retValue = false;
        var user = this.getUserInfo();
        if (user.rolesOnly) {
            user.rolesOnly.forEach(function (role) {
                if (role === name) {
                    retValue = true;
                }
            });
        }
        return retValue;
    };
    AuthService.prototype.hasPermission = function (name) {
        var retValue = false;
        var user = this.getUserInfo();
        if (user.permissionsOnly) {
            user.permissionsOnly.forEach(function (permission) {
                if (permission === name) {
                    retValue = true;
                }
            });
        }
        return retValue;
    };
    AuthService.prototype.isAdmin = function () {
        return this.hasRole("Admin");
    };
    AuthService.prototype.isAgent = function () {
        return this.hasRole("Agent");
    };
    AuthService.prototype.isAgencyAdmin = function () {
        return this.hasRole("Agency_Admin");
    };
    AuthService.prototype.isCommissionsUser = function () {
        return this.hasRole("Agency_Commissions");
    };
    AuthService.prototype.isOnboardingUser = function () {
        return this.hasRole("Agency_Onboarding");
    };
    AuthService.prototype.getRolesTestCase = function (testCase) {
        var roles = "";
        switch (testCase) {
            case 1:
                //Admin - ReadWrite access
                roles = environment.roles_case_1;
                break;
            case 2:
                //Agent-ResourcesPageReadOnly, Agent-HideApplicationButton
                roles = environment.roles_case_2;
                break;
        }
        return roles;
    };
    AuthService.prototype.isPageReadOnly = function (pageName) {
        var isPageReadOnly = false;
        if (environment.ssoEnabled) {
            switch (pageName) {
                case "Resources": {
                    if (this.hasPermission("ResourcesPageReadOnly")) {
                        isPageReadOnly = true;
                    }
                    break;
                }
                default:
                    break;
            }
            // console.log(
            //   '"' +
            //     pageName +
            //     '"' +
            //     " page " +
            //     " readOnly property is: " +
            //     isPageReadOnly
            // );
        }
        return isPageReadOnly;
    };
    AuthService.prototype.verifyAgent = function () {
        return this.http.get(environment.apiUrl + "/verifyAgent");
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.MsalService), i0.inject(i2.HttpClient)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
